/*
 * @Author: your name
 * @Date: 2022-01-10 13:41:29
 * @LastEditTime: 2022-12-19 15:07:44
 * @LastEditors: HuYuliu 10097355522qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \gaoshuofficialwebsite\src\main.ts
 */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';


//electron-plus
import electronPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import * as ElIcons from '@element-plus/icons-vue';

const app = createApp(App);

for (const name in ElIcons) {
	app.component(name, (ElIcons as any)[name]);
}
app.use(electronPlus, { locale: zhCn, autoInsertSpace: true })
	.use(router)
	.mount('#app');