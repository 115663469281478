<!--
 * @Author: your name
 * @Date: 2022-01-10 13:41:29
 * @LastEditTime: 2022-01-10 14:29:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \gaoshuofficialwebsite\src\App.vue
-->
<template>
  <router-view/>
</template>

<style lang="scss">
body{
    margin: 0;
}

</style>
