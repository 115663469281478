/*
 * @Author: your name
 * @Date: 2022-01-10 13:41:29
 * @LastEditTime: 2022-12-20 15:14:30
 * @LastEditors: HuYuliu 10097355522qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \gaoshuofficialwebsite\src\router\index.ts
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name:'loding',
    redirect:{
        name:"index",
    },
    component: () => import( '@/layouts/index.vue'),
    children:[
        {
            path:'/index',
            name:'index',
            meta:{
                title:'首页'
            },
            component:()=>import('@/views/index/index')
        },
        {
            path:'/companyProfile',
            name:'companyProfile',
            meta:{
                title: '公司介绍',
            },
            component: () => import( '@/views/companyProfile/index.vue')
        },
        {
            path:'/solution',
            name:'solution',
            meta:{
                title: '解决方案',
            },
            component: () => import( '@/views/solution')
        },
        {
            path:'/news',
            name:'news',
            meta:{
                title: '新闻',
            },
            component: () => import( '@/views/news/index.vue')
        },
        {
            path:'/productCenter',
            name:'productCenter',
            meta:{
                title: '产品中心',
            },
            component: () => import( '@/views/productCenter/index.vue')
        }
    ]
    
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
